import { graphql, useStaticQuery } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle,
} from 'components/Accordion'
import Copy from 'components/Copy'
import Head from 'components/Head'
import Markdown from 'components/Markdown'
import Row from 'components/Row'
import Text from 'components/Text'
import storage from 'constants/storage'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as cookie from 'libs/cookie'
import * as enterprise from 'libs/enterprise'
import * as user from 'libs/user'
import Page2 from 'templates/Page2'
import headerImage from './images/header.jpg'

export default function Faq() {
  useEventPageVisited('FAQ')
  const globalContext = useGlobalContext()
  const data = useStaticQuery(graphql`
    query {
      categories: allMdx(filter: { frontmatter: { template: { eq: "faqCategory" } } }) {
        nodes {
          id
          frontmatter {
            requiresAuth
            title
          }
        }
      }
      questions: allMdx(
        filter: { frontmatter: { template: { eq: "faq" } } }
        sort: { fields: [frontmatter___title], order: ASC }
      ) {
        nodes {
          id
          body
          frontmatter {
            faqCategory
            requiresAuth
            title
          }
        }
      }
    }
  `)

  const { categories, questions } = data

  const questionsGroups = _.groupBy(questions.nodes, (item) => item.frontmatter.faqCategory[0])

  const enterpriseData = [
    {
      title: 'What is Bold?',
      copy: (
        <p>
          Bold is an at-home, online exercise program designed to help older adults live their
          strongest, happiest, and healthiest lives. As a member, you’ll enjoy instant access to
          personalized exercise programs, daily live classes, and hundreds of on-demand video
          classes to help you improve your strength, flexibility, and balance — so you can chase the
          life you want, at any age.
        </p>
      ),
    },
    {
      title: 'Can I do Bold with friends?',
      copy: (
        <p>
          Your Bold exercise program is tailored to your goals, needs, and preferences, but friends
          can help you stay accountable as you build a new routine.
        </p>
      ),
    },
    {
      title: 'How do I know if I’m doing the exercises correctly?',
      copy: (
        <p>
          Your Bold trainers will demonstrate each exercise, and provide tips and instructions to
          help you with form and technique. Try doing movements in front of a mirror to check on
          your form.
        </p>
      ),
    },
    {
      title: 'How does Bold work?',
      copy: (
        <>
          <p>
            Bold’s at-home online exercise programs include video classes taught by expert trainers
            to help you improve your strength, flexibility, and balance.
          </p>
          <p>
            Once you sign up, we’ll ask you a few questions that we will use to provide you with a
            personalized program. You can take Bold classes anytime, from anywhere. Bold has
            hundreds of on-demand classes and daily live classes.!
          </p>
          <p>
            All you’ll need is your computer, smartphone, or tablet, plus a sturdy chair, water
            bottle, and an open space. See you in class!
          </p>
        </>
      ),
    },
    {
      title: 'What are Bold Assessments?',
      copy: (
        <>
          <p>
            Bold Assessments are designed to check your ability to do certain movements. You’ll be
            guided through each Assessment in a quick video with one of our expert trainers. The
            four Bold Assessments are focused on mobility, balance, and upper and lower body
            strength.
          </p>
          <p>
            Bold Assessments are designed for everyone so don’t sweat the results. They’ll help our
            team adapt your personalized exercise program and allow you to track your progress.
          </p>
        </>
      ),
    },
    {
      title: 'What does Bold do with my data?',
      copy: (
        <p>
          We take your privacy very seriously. Our team protects your personal information with
          best-in-class security and privacy practices. We do not sell your data.
        </p>
      ),
    },
    {
      title: 'What equipment do I need for Bold?',
      copy: (
        <>
          <p>Here’s all you need to take a Bold class:</p>
          <ul>
            <li>
              <p>Your computer, smartphone, or tablet, with internet access.</p>
            </li>
            <li>
              <p>An open area. You’ll need a few feet of empty space to move in.</p>
            </li>
            <li>
              <p>
                A sturdy chair. We recommend using a chair with no wheels and no arms — a basic
                dining chair works well.
              </p>
            </li>
            <li>
              <p>A water bottle — hydration is key!</p>
            </li>
            <li>
              <p>
                Some classes provide options to use equipment like dumbbells or resistance bands,
                however, there are modifications provided if you don’t have that equipment.
                Equipment is never required to participate in Bold.
              </p>
            </li>
          </ul>
        </>
      ),
    },
    {
      title: 'What happens after I complete a Bold program?',
      copy: (
        <>
          <p>
            Finishing a program is no small feat — so congratulations! Don’t worry, the fun doesn’t
            stop here. After you complete a program, you have many options:
          </p>
          <ul>
            <li>
              <p>
                Start a new program. Once you complete a program, we’ll recommend another
                personalized program for you. Each subsequent program will increase in difficulty,
                helping you both maintain and challenge your abilities.
              </p>
            </li>
            <li>
              <p>
                Try classes from the <a href="/app/explore/">Explore page</a>. Use the search bar,
                to take one-off classes based on your preferences. Filter by duration, type of
                class, trainer, difficulty, and position.
              </p>
            </li>
            <li>
              <p>Join a live class and exercise with other Bold members!</p>
            </li>
          </ul>
        </>
      ),
    },
    {
      title: 'Who is Bold for?',
      copy: (
        <p>
          Bold is designed for older adults looking for safe and effective exercise classes
          available anytime, from anywhere. If you’re 50+ and looking to maintain or improve your
          balance, strength or flexibility, Bold is right for you. We offer exercise programs
          customized to your age, preferences, and goals.
        </p>
      ),
    },
    {
      title: 'Who teaches Bold classes?',
      copy: (
        <p>
          Bold trainers are experts on exercise and the science of healthy aging. They specialize in
          a wide range of class styles, such as balance, mobility, strength training, yoga, dance,
          Pilates, Tai Chi, meditation, and more.Meet our amazing trainers here.
        </p>
      ),
    },
    {
      title: 'Why Bold?',
      copy: (
        <>
          <p>
            Bold is designed to help older adults live vibrant lives, full of the activities they
            love. Whether you’re keeping up with grandkids or hitting the road for that trip you’ve
            been dreaming of, Bold helps you get — and stay — stronger and more confident.
          </p>
          <p>
            We’ve created unique exercise programs rooted in the science of fall prevention and pain
            management. Over 1 in 3 older adults fall each year. However, most falls are
            preventable. Research shows that exercise can help boost and condition balance. Bold
            balance programs are proven to lower fall risk by 46%.
          </p>
          <p>
            For more information on how Bold’s science-backed programs, visit the{' '}
            <a href="/science">Science page</a>.
          </p>
        </>
      ),
    },
    {
      title: 'How do I get in touch with support?',
      copy: (
        <p>
          Email us at <a href="mailto:hello@agebold.com">hello@agebold.com</a>, or call us at{' '}
          <a href="tel:18337011545">1-833-701-1545</a> from 9am-5pm Pacific time, Monday through
          Friday
        </p>
      ),
    },
    {
      title: 'How do I know if I’m at risk for a fall?',
      copy: (
        <>
          <p>
            As you get older, you may become more likely to fall.We always recommend talking with
            your doctor about your risk of falling. Certain factors can make you more likely to
            fall, including having fallen before, being on certain medications that make you dizzy,
            and not exercising.
          </p>
          <p>
            Falls can be difficult to talk about, but the good news is that many falls are
            preventable. If you’re looking to increase your balance, strength, and mobility to help
            prevent falls, Bold is here for you.
          </p>
        </>
      ),
    },
    {
      title: 'How often should I exercise?',
      copy: (
        <p>
          We recommend talking with your doctor about your exercise routine. When you sign up for
          Bold, you can choose how many days per week you’d like to exercise, and we’ll design a
          program based on the goals you set. You’ll get a new set of classes each week, and you’ll
          also have unlimited access to Bold’s on-demand video classes, so you can take classes
          whenever you’d like. Activities like walking or biking are a great complement to your Bold
          classes too.
        </p>
      ),
    },
    {
      title: 'What should I do if I fall?',
      copy: (
        <p>
          If you’ve fallen and are unable to get up, call 911 immediately. After any fall, always
          consult with your doctor before starting or returning to an exercise routine.
        </p>
      ),
    },
    {
      title: 'Where can I learn more about exercise?',
      copy: (
        <>
          <p>
            We’re so glad that you’re excited about movement and healthy aging.Here are some great
            ways to continue the conversation and learn more about fitness, healthy aging, and
            investing in your 100-year-self:
          </p>
          <ul>
            <li>
              <p>
                Check out the <a href="/blog">blog</a> for articles on a variety of healthy aging
                topics.
              </p>
            </li>
            <li>
              <p>
                Follow us on{' '}
                <a href="https://www.facebook.com/agebold/" target="_blank">
                  Facebook
                </a>{' '}
                or{' '}
                <a href="https://www.instagram.com/agebold/" target="_blank">
                  Instagram
                </a>
                . Reach out to us at <a href="mailto:hello@agebold.com">hello@agebold.com</a>, and a
                member of our team would be happy to help you on your Bold journey.
              </p>
            </li>
          </ul>
        </>
      ),
    },
    {
      title: 'Why is exercise important for older adults?',
      copy: (
        <>
          <p>
            Exercise helps older adults stay strong, active, and healthy so they can continue living
            the life they want, at any age.
          </p>
          <p>
            Consistent exercise routines can be beneficial for overall health, but Bold specially
            focuses on movements that help you improve strength, flexibility, and balance. Investing
            in your health through exercise helps ensure that you can keep doing the things you love
            for longer.
          </p>
        </>
      ),
    },
    {
      title: 'How do I know if I’m eligible for Bold at no cost through my health plan?',
      copy: (
        <>
          <p>
            Millions of people have access to a Bold Premium membership at no cost through their
            health plan or provider. You may have access through Renew Active by UnitedHealthcare or
            One Pass, which are available through select Medicare Advantage and Medicare
            Supplemental health plans.
          </p>
          <p>
            Check if you’re eligible for Bold at no cost through your health plan by taking this
            survey, or email us at <a href="mailto:hello@agebold.com">hello@agebold.com</a>.
          </p>
        </>
      ),
    },
  ]

  const partner = cookie.getCookie(storage.HOME_KEY) || globalContext.partner
  const isReefOrca =
    enterprise.REEF_ORCA_SOURCES.includes(partner) ||
    user.isEnterpriseReefOrOrcaUser(globalContext.user)

  return (
    <Page2 title="Frequently asked questions" image={headerImage}>
      <Head
        title="FAQ"
        description="Frequently asked questions. How often should I work out? Lifetime access. 30 days free. At home, easy-to-follow workouts."
      />
      {isReefOrca ? (
        <Accordion>
          {enterpriseData.map((item) => (
            <AccordionItem key={item.title}>
              <AccordionItemTitle>
                <Text element="h3" weight="medium" size="large">
                  {item.title}
                </Text>
              </AccordionItemTitle>
              <AccordionItemBody>
                <Copy>{item.copy}</Copy>
              </AccordionItemBody>
            </AccordionItem>
          ))}
        </Accordion>
      ) : (
        Object.keys(questionsGroups).map((groupKey) => {
          const group = questionsGroups[groupKey]
          const category = categories.nodes.find((item) => item.frontmatter.title === groupKey)

          if (category.frontmatter.requiresAuth && !globalContext.isAuthed) return null

          return (
            <Row key={groupKey}>
              <Row size="small">
                <Text element="h2" size="xlarge" weight="semibold">
                  {groupKey}
                </Text>
              </Row>
              <Accordion>
                {group.map((item) => {
                  if (item.requiresAuth && !globalContext.isAuthed) return null

                  return (
                    <AccordionItem key={item.id}>
                      <AccordionItemTitle>
                        <Text element="h3" weight="medium" size="large">
                          {item.frontmatter.title}
                        </Text>
                      </AccordionItemTitle>
                      <AccordionItemBody>
                        <Markdown>{item.body}</Markdown>
                      </AccordionItemBody>
                    </AccordionItem>
                  )
                })}
              </Accordion>
            </Row>
          )
        })
      )}
    </Page2>
  )
}
